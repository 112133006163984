import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LogoHalbar from "../../assets/images/logo-halbar.png";

const PageHead = (props) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{props.title}</title>
        <link rel="shortcut icon" type="image/x-icon" href={LogoHalbar}></link>
      </Helmet>
    </HelmetProvider>
  );
};

export default PageHead;
