import Radial2 from "../../assets/images/radial_2.png";
import Radial3 from "../../assets/images/radial_3.png";

const NotFound = () => {
  return (
    <>
      <div className="flex h-screen items-center justify-center relative">
        <div className="text-center text-[#1ABC9C] z-10">
          <h1 className="text-4xl font-bold mb-4">404 | NOT FOUND</h1>
        </div>
        <img
          src={Radial2}
          alt="Overlay"
          className="absolute bottom-0 right-0 md:w-[300px] w-64 h-auto object-cover z-0"
        />
        <img
          src={Radial3}
          alt="Overlay"
          className="absolute top-0 left-0 md:w-[300px] w-64 h-auto object-cover z-0"
        />
      </div>
    </>
  );
};

export default NotFound;
