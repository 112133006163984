import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import NotFoundPage from "./pages/NotFoundPage";

const Home = lazy(() => import("./pages/Home"));
const Data = lazy(() => import("./pages/Data"));
const Consultation = lazy(() => import("./pages/Consultation"));
const PublicationInformation = lazy(() =>
  import("./pages/PublicationInformation")
);
const DetailInformationsPage = lazy(() =>
  import("./pages/DetailInformationsPage")
);
const VisitorStatisticsPage = lazy(() =>
  import("./pages/VisitorStatisticsPage")
);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCBtASB5-UCNenX3JxR0CKefgGRhDH9ELg",
  authDomain: "debest-halbar.firebaseapp.com",
  projectId: "debest-halbar",
  storageBucket: "debest-halbar.appspot.com",
  messagingSenderId: "1069023068395",
  appId: "1:1069023068395:web:28e37c9abfade5f8ad3eae",
  measurementId: "G-XBS3ZMFY4G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, "app_open");
const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/data"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Data />
            </Suspense>
          }
        />
        <Route
          path="/konsultasi"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Consultation />
            </Suspense>
          }
        />
        <Route
          path="/publikasi-informasi"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <PublicationInformation />
            </Suspense>
          }
        />
        <Route
          path="/detail-publikasi-informasi/:id"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <DetailInformationsPage />
            </Suspense>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
