import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../assets/styles/index.css";

import PageHead from "../components/PageHead/PageHead";
import NotFound from "../components/NotFound/NotFound";

function NotFoundPage() {
  useEffect(() => {
    AOS.init({
      disable: false,
      startEvent: "DOMContentLoaded",
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      useClassNames: false,
      disableMutationObserver: false,
      debounceDelay: 50,
      throttleDelay: 99,
      offset: 120,
      easing: "ease",
      duration: 800,
      once: false,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  return (
    <>
      <PageHead title="Debest Halmahera Barat" />
      <div className="font-poppins">
        <NotFound />
      </div>
    </>
  );
}

export default NotFoundPage;
